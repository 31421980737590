import React, {useState, useContext, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  ProductContext,
  ContainerContext,
  StoreContext,
  ProductSelectionContext
} from '@oracle-cx-commerce/react-ui/contexts';
import {handleTealiumAddToCart} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/data-tagging/ltd-add-to-cart';
import ProductInventoryStatus from '@oracle-cx-commerce/react-widgets/product/product-inventory-status/component';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {Modal, Button} from '../../../../../../ltd-ui';
import {isEmptyObject, noop} from '@oracle-cx-commerce/utils/generic';
import LTDPageLoader from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/page-loader';
import QuickViewPlaceholder from '@oracle-cx-commerce/react-widgets/product-listing/product-result-image-quick-view/components/quick-view-placeholder';
import {getCurrentOrder, getSku, getSkus, getSite} from '@oracle-cx-commerce/commerce-utils/selector';
import Link from '@oracle-cx-commerce/react-components/link';
import Portal from '@oracle-cx-commerce/react-components/portal';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import LTDProductPrice from '../qv-product-price';
import LTDProductName from '../qv-product-name';
import LTDProductImageViewer from '../qv-image-viewer';
import LTDProductLongDescription from '../qv-long-description';
import LTDProductLegalLines from '../qv-product-legal-lines';
import LTDProductWarnings from '../qv-product-warnings';
import LTDProductPersonalization from '../qv-product-personalization';
import LTDProductSocialSharing from '../../../../product/ltd-product-social-sharing';
import LTDProductVariantOptions from '../qv-product-variant-options';
import LTDProductQuantity from '../qv-product-quantity';
import LTDAddToCartButton from '../qv-add-to-cart-button';
import ProductPinCode from '../../../../product/product-pin-code';
import ProductShippingInfo from '../product-shipping-info';
import LTDProductCollectionLink from '../ltd-product-collection-link';
import LTDProductBrands from '../../../../product/ltd-product-brands';
import LTDProductStars from '../qv-product-review-stars';
import css from './styles.css';
import QVLTDPromoBadge from '../../../ltd-promo-badge';

/**
 * Displays the quick view pop up
 *
 * @param {function} props.closeQuickView function that handles closing the pop up
 * @param {boolean} props.showQuickView whether the Quick View pop up is displayed or not
 * @param {string} props.imageUrl the url of the product image in the plp
 * @param {string} props.altText the altText associated with the product image in the plp
 * @param {object} props.product the product details to be displayed in quick view
 */
const QuickView = props => {
  const {closeQuickView, showQuickView, product, fullDetailsButtonText, isRecommendation, skuId: recSkuId = ''} = props;
  const {variantOptionsSeed, variantToSkuLookup = {}, childSKUs = []} = product;
  const [badgeName, setBadgeName] = useState(null);
  const [addToCartInProgress, setAddToCartInProgress] = useState(false);
  const [isAddToCartButtonPressed, setIsAddToCartButtonPressed] = useState(false);

  const [productPersonalization, setProductPersonalization] = useState(false);
  const [imagePersonalizationQV, setImagePersonalizationQV] = useState(false);
  const [ltd_personalizationData, setPersonalizationData] = useState([]);
  const {record = {}, route = ''} = useContext(ProductContext);
  const store = useContext(StoreContext);
  const {productionURL} = useSelector(getSite);

  const {commerceItems = {}} = useSelector(getCurrentOrder);

  const skusObj = useSelector(getSkus) || [];

  const getNotDummySkus = props => {
    let skus = [];
    if (childSKUs && childSKUs.length && !isEmptyObject(skusObj)) {
      if (childSKUs.length === 1) {
        skus = childSKUs.map(childSkuId => skusObj[childSkuId]);
      } else {
        skus = Object.keys(skusObj).map(sku => skusObj[sku]);
      }
    }

    if (!skus || !skus[0] || !Array.isArray(skus)) return null;
    const notDummySkus = skus.filter(sku => !sku.ltd_isDummySku);

    if (props) {
      return notDummySkus.filter(sku => childSKUs?.includes(sku.repositoryId));
    }

    return notDummySkus;
  };

  const returnPinCode = notDummySkus => {
    if (isEmptyObject(notDummySkus)) {
      return null;
    }

    return notDummySkus.length === 1 ? notDummySkus[0].ltd_defaultPinCodeSku : null;
  };

  const pinCodeDefault = returnPinCode(getNotDummySkus());

  const skuNotInProducts = (childSKUs, skuId) => {
    const sku = childSKUs.find(chidSKU => chidSKU === skuId);

    return sku === undefined ? true : false;
  };

  const initialState = {
    skuId: null,
    variantOptions: {},
    qty: 1
  };

  const [selections, setSelections] = useState(initialState);
  const isSelectedSkuExclusive = useCallback(
    skuId => {
      const fullSku = getSku(store.getState(), {skuId});
      const isExclusive = fullSku.ltd_isExclusive === 'Yes' ? true : false;

      return isExclusive;
    },
    [store]
  );

  const isOneOfSkusExclusive = useCallback(
    skus => {
      let isExclusive = false;
      skus.every(sku => {
        const fullSku = getSku(store.getState(), {skuId: sku});
        if (fullSku.ltd_isExclusive === 'Yes') {
          isExclusive = true;

          return false;
        }
      });

      return isExclusive;
    },
    [store]
  );

  useEffect(() => {
    if (!isEmptyObject(product)) {
      if (!product.ltd_badge) {
        const isExclusive = selections.skuId
          ? isSelectedSkuExclusive(selections.skuId)
          : isOneOfSkusExclusive(childSKUs);
        if (isExclusive) setBadgeName('Exclusive');
      } else {
        setBadgeName(product.ltd_badge);
      }
    }
  }, [childSKUs, isOneOfSkusExclusive, isSelectedSkuExclusive, product, selections]);

  if (
    isEmptyObject(selections) ||
    (isEmptyObject(variantOptionsSeed) && selections.skuId !== variantToSkuLookup['']) ||
    (!isEmptyObject(variantOptionsSeed) && selections.skuId && skuNotInProducts(childSKUs, selections.skuId))
  ) {
    setSelections({...selections, skuId: variantToSkuLookup[''], pinCodeDefault});
  }

  const {action} = useContext(StoreContext);

  const onClose = useCallback(() => {
    setSelections(initialState);
    closeQuickView();
  }, [setSelections, initialState, closeQuickView]);

  /**
   * Handler for AddToCartButton
   * Dispatches action when add to cart is clicked
   * Closes quick view on success
   * @param  {Object} Object
   */
  const onAddToCart = useCallback(
    ({payload, onNotOk = noop, onComplete = noop}) => {
      if (history && history.state) {
        const {pin, isBook} = history.state;

        if (pin && isBook) {
          const items = Object.values(commerceItems || {});

          let commerceItemCustomProperties = null;

          items.find(item => {
            if (item.catRefId === payload.catRefId && item.productId === payload.productId) {
              const {dynamicProperties = []} = item;

              const property = dynamicProperties.find(prop => prop.id === 'ltd_productCustomProperties');

              if (property && property.value) {
                const objectProps = JSON.parse(property.value);

                if (objectProps.pin === pin) {
                  commerceItemCustomProperties = property.value;
                }
              }
            }

            return commerceItemCustomProperties;
          });

          if (commerceItemCustomProperties) {
            payload.ltd_productCustomProperties = commerceItemCustomProperties;
          } else {
            payload.ltd_productCustomProperties = JSON.stringify({pin});
          }
        }
      }

      const productsEventData = JSON.parse(JSON.stringify(payload));

      if (!isEmptyObject(commerceItems)) {
        const itemCart = Object.values(commerceItems).find(
          item => item.catRefId === payload.catRefId && payload.productId === item.productId
        );
        if (
          itemCart?.commerceItemId &&
          !itemCart?.giftWithPurchaseCommerceItemMarkers?.length &&
          !payload.ltd_personalizationData
        ) {
          payload.commerceItemId = itemCart.commerceItemId;
          payload.quantity = parseInt(payload.quantity);
        }
      }
      let payloadRequest = {
        items: [{...payload}]
      };
      action('addItemsToCart', payloadRequest)
        .then(response => {
          if (response.ok === true) {
            let locationAdded = 'addedFromQv';
            if (isRecommendation) {
              locationAdded = 'addedFromProdRecQV';
            }
            handleTealiumAddToCart(store, [{...payload}], [{...productsEventData}], locationAdded);
            onClose();
          } else {
            onNotOk(response);
            onClose();
          }
        })
        .catch(error => {
          onNotOk({error});
        })
        .finally(onComplete);
    },
    [action, commerceItems, onClose]
  );

  const viewProductDetailsButton = () => {
    if (isRecommendation) {
      const {id} = product;
      action('_trackProductRecs', {[id]: true});
    }

    window.localStorage.setItem('pages', JSON.stringify(''));
    closeQuickView();
  };

  const productSelection = {
    skuId: selections.skuId,
    pinCodeDefault,
    qty: selections.qty,
    ltd_personalized: selections?.ltd_personalized
  };

  const ContainerContexts = {
    selections,
    setSelections,
    ltd_personalizationData,
    setPersonalizationData,
    imagePersonalizationQV,
    setImagePersonalizationQV
  };

  const ProductSelectionContexts = {
    addToCartInProgress,
    setAddToCartInProgress,
    productSelection,
    productPersonalization,
    setProductPersonalization
  };

  const ProductContexts = {
    record,
    route,
    product,
    selections
  };

  const {attributes} = record;
  const skuId = attributes && !attributes['sku.x_skuConcat']?.includes('dummy') && attributes?.['sku.repositoryId'][0];
  const currentSku = isRecommendation ? recSkuId : skuId;

  const variantProps = {...props, skuId: currentSku};

  return (
    <Styled id="LTDQuickView" css={css}>
      <>
        <Portal>
          <Modal
            active={showQuickView}
            hideModal={() => onClose()}
            className="QuickView__Modal"
          >
            <div className="QuickView">
              <LTDPageLoader show={isEmptyObject(product)}>
                <QuickViewPlaceholder />
              </LTDPageLoader>
              <ProductContext.Provider
                value={{...ProductContexts, isAddToCartButtonPressed, setIsAddToCartButtonPressed}}
              >
                <ProductSelectionContext.Provider value={ProductSelectionContexts}>
                  <ContainerContext.Provider value={ContainerContexts}>
                    <LTDProductImageViewer {...props} />
                    <div className="QuickView__ProductDetails">
                      <LTDProductName />
                      <LTDProductStars {...props} onClose={onClose} />

                      <LTDProductPrice {...props} />
                      <QVLTDPromoBadge {...props} productId={product.repositoryId} isQV />
                      <LTDProductVariantOptions {...variantProps} />
                      <LTDProductPersonalization {...props} />
                      <LTDProductCollectionLink {...props} />
                      <div className="QuickView__ProductDetails__MiddleSection">
                        <div className="QuickView__ProductDetails__QuantityAndButtonWrapper">
                          <LTDProductQuantity {...props} />
                          <LTDAddToCartButton onAddToCart={onAddToCart} {...props} />
                        </div>
                        <div>
                          <div className="QuickView__ProductDetails__MiddleSection__CodeAndStatus">
                            <ProductPinCode {...props} />
                            <ProductInventoryStatus {...props} />
                          </div>
                          <ProductShippingInfo {...props} />
                        </div>
                      </div>
                      <LTDProductSocialSharing
                        {...props}
                        pathOverride={`${productionURL}/${product.route}`}
                        onClose={onClose}
                      />
                      <div className="QuickView__ProductDetails__FullDetailsButtonWrapper">
                        <Link onClick={() => viewProductDetailsButton()} href={route} ariaHidden="true">
                          <Button className="ltd-ui-button outlined">{fullDetailsButtonText}</Button>
                        </Link>
                      </div>
                      <LTDProductBrands productInfo={product} />
                      <LTDProductLongDescription {...props} />
                      <LTDProductWarnings />
                      <LTDProductLegalLines />
                    </div>
                  </ContainerContext.Provider>
                </ProductSelectionContext.Provider>
              </ProductContext.Provider>
            </div>
          </Modal>
        </Portal>
      </>
    </Styled>
  );
};

QuickView.propTypes = {
  closeQuickView: PropTypes.func.isRequired,
  showQuickView: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  product: PropTypes.shape({
    variantOptionsSeed: PropTypes.shape({}),
    variantToSkuLookup: PropTypes.shape({})
  }).isRequired
};

export default QuickView;
