import React, { forwardRef } from "react";

const Checkbox = forwardRef(
  (
    {
      children,
      name,
      className,
      isDisabled = false,
      isDefaultChecked = false,
      isLabelAlignedTop = false,
      onChange,
      type = "checkbox",
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={`ltd-ui-checkbox ${isLabelAlignedTop ? "label-align-top" : ""} ${className ? className : ''}`}
        hidden={type === "hidden" ? true : false}
      >
        <input
          tabIndex={0}
          type={type}
          id={`rememberCheckbox${name}`}
          disabled={isDisabled}
          defaultChecked={isDefaultChecked}
          name={name}
          onChange={onChange}
          ref={ref}
          {...props}
        />
        {children ? (
          <label
            hidden={type === "hidden" ? true : false}
            onChange={onChange}
            isDisabled={isDisabled}
            htmlFor={`rememberCheckbox${name}`}
          >
            {children}
          </label>
        ) : (
          ""
        )}
      </div>
    );
  }
);

export { Checkbox };
