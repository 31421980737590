import React from 'react';
import {normalizeText} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
import {FOOTER_PREFIX} from '@oracle-cx-commerce/ltd-store/src/plugins/constants/analytics';
import Styled from '@oracle-cx-commerce/react-components/styled';
import {PAGE_FREE_CATALOG_LINK} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/commerce-utils/constants/page-links';
import {triggerEvent} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/data-tagging/events/common';
import GenericLink from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/common/generic-link';

/*
 * Uncomment the following line to get the parameter substitution
 * function, e.g. t(someParameterizedResourceString, "someValue").
 */

import css from './styles.css';

const FooterLinks = props => {
  const {LinksTitle, LinkWithIcons, displayAndURL = '[]', linkBehavior = '_self'} = props;

  const links = JSON.parse(displayAndURL.replace(/'/g, '"'));

  const CreateLink = props => {
    const {href, title, children} = props;
    let linkProps;

    if (href) {
      if (href === PAGE_FREE_CATALOG_LINK) {
        linkProps = {...linkProps, onClick: () => triggerEvent({e_lead_form_init: 't'})};
      }
      linkProps = {...linkProps, href, title};
    }

    if (linkBehavior === '_self') {
      // return <Link {...linkProps}>{children}</Link>;
    }
    linkProps = {...linkProps, target: linkBehavior};

    return <GenericLink {...linkProps}>{children}</GenericLink>;
  };

  return (
    <Styled id="FooterLinks" css={css}>
      <div className="FooterLinks__Container">
        <span className="FooterLinks__Title">{LinksTitle}</span>

        <ul className="FooterLinks__LinkList">
          {links.map((item, index) => (
            <li
              key={item['0']}
              className="FooterLinks__LinkItem"
              id={`${FOOTER_PREFIX}${normalizeText(props[item['0']] || item['0'])}-${index}`}
            >
              {!LinkWithIcons && <CreateLink href={item['1']}>{props[item['0']] || item['0']}</CreateLink>}
              {LinkWithIcons && (
                <CreateLink href={item['1']} title={item['0']}>
                  <span aria-label={props[item['0']] || item['0']} className={props[item['0']] || item['0']}></span>
                </CreateLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    </Styled>
  );
};

export default FooterLinks;
