import React from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';

import {LEFTNAV_PREFIX} from '@oracle-cx-commerce/ltd-store/src/plugins/constants/analytics';
import {normalizeText} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';

import css from './styles.css';

const LinksListCustom = props => {
  const {LinksTitle, LinkWithIcons, displayAndURL = '[]', linkBehavior = '_self'} = props;

  const links = JSON.parse(displayAndURL.replace(/'/g, '"'));

  const CreateLink = props => {
    const {href, children} = props;
    let linkProps;

    if (href) {
      linkProps = {...linkProps, href, title: children};
    }

    if (linkBehavior === '_self') {
      // return <Link {...linkProps}>{children}</Link>;
    }
    linkProps = {...linkProps, target: linkBehavior};

    return <a {...linkProps}>{children}</a>;
  };

  return (
    <Styled id="LinksListCustom" css={css}>
      <div className="LinksListCustom__Container">
        <span className="LinksListCustom__Title">{LinksTitle}</span>

        <ul className="LinksListCustom__LinkList">
          {links.map(item => (
            <li
              key={item['0']}
              className="LinksListCustom__LinkItem"
              id={`${LEFTNAV_PREFIX}${normalizeText(props[item['0']] || item['0'])}`}
            >
              {!LinkWithIcons && <CreateLink href={item['1']}>{props[item['0']] || item['0']}</CreateLink>}
              {LinkWithIcons && (
                <CreateLink href={item['1']}>
                  <span className={props[item['0']] || item['0']}></span>
                </CreateLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    </Styled>
  );
};

export default LinksListCustom;
