import React, {useCallback, useContext, useState, useEffect, useRef} from 'react';
import Styled from '@oracle-cx-commerce/react-components/styled';
import Form from '@oracle-cx-commerce/react-components/form';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import {t, noop} from '@oracle-cx-commerce/utils/generic';
import {useNavigator} from '@oracle-cx-commerce/react-components/link';
import {getPageTitle} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/data-tagging/utag/index';
import {
  getFilterBySearchTerm,
  formatTypeAheadValue,
  isPinCodeSearch
} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/commerce-utils/constants';
import {normalizeText} from '@oracle-cx-commerce/ltd-store/src/plugins/utils/analytics';
import {HEADER_PREFIX} from '@oracle-cx-commerce/ltd-store/src/plugins/constants/analytics';
import TypeaheadResultsPanel from '../typeahead-results-panel';
import css from './styles.css';

/**
 * Component that displays a generic search box form with typeahead.
 */
const SearchBoxCommon = props => {
  const {
    records,
    searchToggle,
    searchInput,
    locale,
    numberOfSuggestions,
    initialInputValue,
    actionClear,
    textEnterSearch,
    isMobile,
    SearchIcon,
    CloseButton,
    alreadySubmit = false,
    setAlreadySubmit = noop,
    page
  } = props;

  const [inputValue, setInputValue] = useState(initialInputValue);
  const [isClickedOutside, setIsClickedOutside] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const {action} = useContext(StoreContext);
  const goToPage = useNavigator();

  const SEARCH_QUERY_PARAM = 'Ntt';

  const dropdown = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdown?.current && !dropdown?.current.contains(event.target) && inputValue) {
        setIsClickedOutside(true);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputValue, dropdown]);

  /**
   * Called when the search text is updated.
   */
  const onInput = useCallback(
    async event => {
      const {value} = event.target;
      setInputValue(value);
      setAlreadySubmit(false);
      setIsClickedOutside(false);

      // Issue a typeahead request if input is at least one character for
      // Chinese, Japanese and Korean locales, or two characters for all other locales
      if (value.length > (locale.startsWith('zh') || locale.startsWith('ja') || locale.startsWith('ko') ? 0 : 1)) {
        sessionStorage.setItem(
          'termTypeAhead',
          JSON.stringify({typeAheadItem: false, termSearch: value, recommendation: false})
        );
        const NfParamProduct = `product.ltd_startDate|LTEQ+${new Date().getTime()}||product.ltd_endDate|GTEQ+${new Date().getTime()}`;
        const NfParamSKU = `sku-LTDProduct.ltd_startDateSku|LTEQ+${new Date().getTime()}||sku-LTDProduct.ltd_endDateSku|GTEQ+${new Date().getTime()}`;

        await action('typeahead', {
          text: formatTypeAheadValue(value),
          maxResults: numberOfSuggestions || 5,
          searchServicePath: 'Default/osf/ltd-typeahead',
          query: {
            Nr: getFilterBySearchTerm(value, true),
            Nf: `${NfParamProduct}||${NfParamSKU}`
          }
        }).catch(() => {});
      } else {
        action('clearTypeahead').catch(() => {});
      }
    },
    [locale, action, numberOfSuggestions, setAlreadySubmit]
  );

  const clearInput = () => {
    setInputValue('');
  };

  const hideKeyboard = () => {
    document.activeElement.blur();
  };

  /**
   * Called when the search form is submitted.
   */
  const onSubmit = useCallback(() => {
    // Don't submit search if input is empty
    if (!inputValue.trim()) {
      return false;
    }

    const obj = sessionStorage.getItem('termTypeAhead') || '{}';
    const values = JSON.parse(obj);
    sessionStorage.setItem('termTypeAhead', JSON.stringify({...values, typeAheadItem: true}));

    action('clearTypeahead').catch(() => {});

    setAlreadySubmit(true);
    hideKeyboard();
    clearInput();

    if (records.length === 1) {
      const productRoute = records?.[0]?.attributes?.['product.route'] || '';
      const route = Array.isArray(productRoute) ? productRoute[0]?.replace(/^\//, '') : productRoute.replace(/^\//, '');

      if (route) {
        if (isPinCodeSearch(inputValue)) {
          const pageTitle = getPageTitle(page);

          window.sessionStorage.setItem('previousPage', `pin-search:${pageTitle}`);
          window.sessionStorage.setItem('prevPage', `pin-search:${pageTitle}`);
        }

        goToPage(route);

        return false;
      }
    }

    // Close search popover, clear search box, hide clear button
    if (searchToggle?.current) {
      searchToggle.current.checked = false;
    }
  }, [action, inputValue, searchToggle, setAlreadySubmit, records, goToPage, page]);

  /**
   * Called when the search form is reset ("Clear" button pressed).
   */
  const onReset = useCallback(() => {
    setInputValue('');
    action('clearTypeahead');
    searchInput?.current.focus();
  }, [action, setInputValue, searchInput]);

  useEffect(() => {
    setIsDisabled(false);
  }, []);

  return (
    <Styled id="SearchBoxCommon" css={css}>
      <div className="SearchBoxCommon__Content">
        <div className="SearchBoxCommon__Buttons">
          <div className="SearchBoxCommon__Form" style={{zIndex: inputValue ? 99 : 9}}>
            <Form method="GET" action="browse" onSubmit={onSubmit}>
              {/* Search icon on the left side if mobile. */}
              {isMobile && SearchIcon}
              <input
                name={SEARCH_QUERY_PARAM}
                className="SearchBoxCommon__Input"
                ref={searchInput}
                type="search"
                disabled={isDisabled}
                value={inputValue}
                placeholder={t(textEnterSearch)}
                autoComplete="off"
                onChange={onInput}
                aria-label="Search bar"
                style={{borderColor: inputValue && records.length > 0 ? 'var(--primary-200)' : 'var(--gray-400)'}}
                id={`${HEADER_PREFIX}${normalizeText('typeahead-input')}`}
              />
              <input
                type="reset"
                className="SearchBoxCommon__ClearButton"
                style={{visibility: inputValue ? 'visible' : 'hidden'}}
                value={t(actionClear)}
                onClick={onReset}
                id={`${HEADER_PREFIX}${normalizeText('typeahead-reset')}`}
              />
              {/* Search icon on the right side if desktop. 
               Disable the search button for desktop if there is no input */}
              {!isMobile && SearchIcon && <SearchIcon isDisabled={!inputValue} />}
            </Form>
          </div>
          {/* Button to close the search modal for mobile. */}
          <div
            className="common-backdrop"
            style={{visibility: inputValue && records.length > 0 ? 'visible' : 'hidden'}}
            onClick={onReset}
          ></div>
          {isMobile && CloseButton}
        </div>
        <div ref={dropdown}>
          {!alreadySubmit && !isClickedOutside && (
            <TypeaheadResultsPanel
              records={records}
              inputValue={inputValue}
              isClickedOutside={isClickedOutside}
              {...props}
            />
          )}
        </div>
      </div>
    </Styled>
  );
};

/**
 * Use an @oracle-cx-commerce/react-ui.connect component to arrange for the component
 * to be rendered when its state changes.
 */
export default React.memo(SearchBoxCommon);
