import {isEmptyObject} from '@oracle-cx-commerce/utils/generic';
import {getTotalOrderValue} from '@oracle-cx-commerce/ltd-store/src/plugins/components/LTD/utils/functions/get-total-order-value';

// first we use encodeURIComponent to get percent-encoded UTF-8,
// then we convert the percent encodings into raw bytes which
// can be fed into btoa.
export const b64EncodeUnicode = str => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode(`0x${p1}`);
    })
  );
};

export const sha256Encode = async message => {
  try {
    const msgUint8 = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  }
  catch (error) {
    console.error(error);
  }
}

export const validateDatalayerPayload = payload => {
  for (const index in payload) {
    if (typeof payload[index] === 'undefined') {
      return false;
    }
  }

  return true;
};

const getCookie = cname => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

export const getGeolocation = window => {
  if (typeof window !== 'undefined') {
    const country = getCookie('GEO_COUNTRY_CODE');
    const geoState = getCookie('GEO_STATE');
    const geoCity = getCookie('GEO_CITY');

    return {
      geoCity,
      geoState,
      country
    };
  }
};

export const getMobileAndTablet = window => {
  if (typeof window != 'undefined') {
    const isMobile = getCookie('IS_MOBILE');
    const isTablet = getCookie('IS_TABLET');

    return {
      isMobile,
      isTablet
    };
  }
};

export const getPayerNumber = currentProfile => {
  if (!isEmptyObject(currentProfile)) {
    const {dynamicProperties = []} = currentProfile;
    const property = dynamicProperties.find(property => property.id === 'ltd_payerNumber');

    const {value = ''} = property || {};

    const payerNumber = value;

    return payerNumber;
  }

  return '';
};

export const getCommonDatalayerProperties = (
  utagPayload,
  cartDetails,
  defaultAddress,
  currentProfile,
  priceInfo,
  currentOrder = {},
  shippingConfig = {},
  cartView = false
) => {
  const {geoCity, geoState, country} = getGeolocation(window);
  const {isMobile, isTablet} = getMobileAndTablet(window);
  const payerNumber = getPayerNumber(currentProfile);
  const additionalShippingSum = cartDetails.additionalShipping
    ? cartDetails.additionalShipping.reduce((pv, cv) => pv + cv, 0)
    : 0;

  let utagData = {
    ...utagPayload,
    cart_product_category: cartDetails.productCategory,
    cart_product_category_id: cartDetails.productCategoryId,
    cart_product_child_sku: cartDetails.childSKUSDepartment,
    cart_product_child_skuid: cartDetails.childSKUs,
    cart_product_sub_subcategory: cartDetails.subSubCatName,
    cart_product_sub_subcategory_id: cartDetails.subSubCatId,
    cart_product_subcategory: cartDetails.subCatName,
    cart_product_subcategory_id: cartDetails.subCatId,
    cart_product_parent_sku: cartDetails.parentSku,
    cart_product_discount: cartDetails.discount,
    cart_product_id: cartDetails.productId,
    cart_product_name: cartDetails.productName,
    cart_product_qty: cartDetails.quantity,
    cart_product_price: cartDetails?.price || '0',
    cart_sku_article_id: cartDetails.articleId,
    cart_sku_upc: cartDetails.skuUpc,
    sku_article_id: cartDetails.articleId,
    sku_upc: cartDetails.skuUpc,
    order_shipping: priceInfo ? priceInfo?.shipping?.toString() : '0',
    order_subtotal: priceInfo ? priceInfo?.subTotal?.toString() : '0',
    merchandise_total: priceInfo ? priceInfo?.amount?.toString() : '0',
    order_tax: priceInfo ? priceInfo?.tax?.toString() : '0',
    order_total: getTotalOrderValue(currentOrder, shippingConfig),
    order_additional_shipping: priceInfo?.shippingSurchargeValue || '0',
    prodPriceCents: cartDetails.priceInCents,
    product_category: cartDetails.productCategory,
    product_category_id: cartDetails.productCatId,
    product_category_id_str: cartDetails.productCategory,
    product_child_sku: cartDetails.parentSku,
    product_child_skuid: cartDetails.childSKUs,
    product_discount: cartDetails.discount,
    product_discount_share: cartDetails.discountShare,
    product_id: cartDetails.productId,
    product_name: cartDetails.name,
    product_parent_sku: cartDetails.parentSku,
    product_price: cartDetails.price,
    product_qty: cartDetails.quantity,
    product_sub_subcategory: cartDetails.subSubCatName,
    product_sub_subcategory_id: cartDetails.subSubCatId,
    product_subcategory: cartDetails.subCatName,
    product_subcategory_id: cartDetails.subCatId,
    sale_items: cartDetails.itemsOnSale,
    shipping_method: cartDetails.shippingMethod,
    geo_city: geoCity,
    geo_state: geoState,
    is_mobile: isMobile ? '1' : '0',
    is_tablet: isTablet ? '1' : '0',
    customer_country: country,
    cart_total: getTotalOrderValue(currentOrder, shippingConfig),
    tw_event: 'pageView',
    ...(cartView && {e_cart_view: 't'})
  };

  if (defaultAddress) {
    utagData = {
      ...utagData,
      customer_city: defaultAddress.city,
      customer_state: defaultAddress.state,
      customer_zip: defaultAddress.postalCode,
      customer_country: defaultAddress.country
    };
  }

  if (currentProfile) {
    utagData = {
      ...utagData,
      payer_number: payerNumber,
      customer_fname: currentProfile.firstName,
      customer_lname: currentProfile.lastName,
      mobile_number: currentProfile.daytimeTelephoneNumber || ''
    };

    const shippingAddressEmail = Object.values(currentOrder?.shippingGroups)[0]?.shippingAddress?.email;
    const email = currentProfile?.email || shippingAddressEmail;

    if (email) {
      utagData.email_token = b64EncodeUnicode(email);
    }

    if (currentProfile?.id) {
      utagData.encrypted_profile_id = b64EncodeUnicode(currentProfile.id);
    }
  }

  if (!utagData.payer_number) {
    delete utagData.payer_number;
  }

  return utagData;
};

export const getPreviousPageName = (window, currentPageName) => {
  if (typeof window !== 'undefined') {
    if (
      window.sessionStorage &&
      window.sessionStorage.previousPage &&
      window.sessionStorage.previousPage !== currentPageName
    ) {
      const previousPageName = window.sessionStorage.previousPage;
      window.sessionStorage.setItem('previousPage', currentPageName);

      return previousPageName;
    }

    window.sessionStorage.setItem('previousPage', currentPageName);

    return '';
  }

  return '';
};
